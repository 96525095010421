$breakpoint-xxl: 1600 !default;

@import "@7egend/web.core/lib/styles/libs";

@media screen and (max-width: 1023px) {
    .container {
        max-width: 100%;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1940px) {
    .container {
        width: calc(100% - 220px);
        max-width: 1600px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
    .container {
        width: calc(100% - 160px);
        max-width: 1600px;
    }
}

@media screen and (min-width: 576px) and (max-width: 1023px) {
    .container {
        max-width: 100%;
    }
}

.container {
    padding-left: 12px;
    padding-right: 12px;

    @media screen and (max-width: 1023px) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.row {
    margin-left: -12px;
    margin-right: -12px;

    @media screen and (max-width: 1023px) {
        margin-left: -24px;
        margin-right: -24px;
    }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
    padding-left: 12px;
    padding-right: 12px;

    @media screen and (max-width: 1023px) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('/assets/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bo';
    src: url('/assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('/assets/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('/assets/fonts/ProximaNova-Black.woff2') format('woff2'),
        url('/assets/fonts/ProximaNova-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Proxima Nova EBo';
    src: url('/assets/fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('/assets/fonts/ProximaNova-Light.woff2') format('woff2'),
        url('/assets/fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
