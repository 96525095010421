$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "/assets/svgs" !default;

$icon-arrow-right: "\e91b";
$icon-facebook: "\e917";
$icon-snapchat: "\e918";
$icon-instagram: "\e919";
$icon-location: "\e900";
$icon-twitter: "\e91a";
$icon-professional-nutrition: "\e901";
$icon-greatclub: "\e902";
$icon-calendar: "\e903";
$icon-time: "\e904";
$icon-user: "\e905";
$icon-play: "\e906";
$icon-opportunity: "\e907";
$icon-industry: "\e908";
$icon-consumers: "\e909";
$icon-record: "\e90a";
$icon-small-group-training: "\e90b";
$icon-personal-training: "\e90c";
$icon-level: "\e90d";
$icon-years: "\e90e";
$icon-class-time: "\e90f";
$icon-category-strength: "\e910";
$icon-category-mindbody: "\e911";
$icon-category-zumba: "\e912";
$icon-category-dance: "\e913";
$icon-category-crunch: "\e914";
$icon-best-instructors: "\e915";
$icon-personal-programs: "\e916";
$icon-filter: "\e91c";
$icon-filter-selected1: "\e91d";
$icon-filter-selected2: "\e91e";
$icon-filter-selected3: "\e91f";
$icon-search: "\e920";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?663i2q');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?663i2q#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?663i2q') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?663i2q') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?663i2q##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;     
    color: #fff;
  }
}
.icon-snapchat {
  &:before {
    content: $icon-snapchat;     
    color: #fff;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;     
    color: #fff;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;     
    color: #fff;
  }
}
.icon-location {
  &:before {
    content: $icon-location;     
    color: #fff;
  }
}
.icon-professional-nutrition {
  &:before {
    content: $icon-professional-nutrition;     
    color: #ff2e36;
  }
}
.icon-greatclub {
  &:before {
    content: $icon-greatclub;     
    color: #fd2e35;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-time {
  &:before {
    content: $icon-time;     
    color: #ff2e36;
  }
}
.icon-user {
  &:before {
    content: $icon-user;     
    color: #fff;
  }
}
.icon-play {
  &:before {
    content: $icon-play;     
    color: #fff;
  }
}
.icon-opportunity {
  &:before {
    content: $icon-opportunity; 
  }
}
.icon-industry {
  &:before {
    content: $icon-industry; 
  }
}
.icon-consumers {
  &:before {
    content: $icon-consumers; 
  }
}
.icon-record {
  &:before {
    content: $icon-record; 
  }
}
.icon-small-group-training {
  &:before {
    content: $icon-small-group-training;     
    color: #ff2e36;
  }
}
.icon-personal-training {
  &:before {
    content: $icon-personal-training;     
    color: #ff2e36;
  }
}
.icon-level {
  &:before {
    content: $icon-level; 
  }
}
.icon-years {
  &:before {
    content: $icon-years; 
  }
}
.icon-class-time {
  &:before {
    content: $icon-class-time; 
  }
}
.icon-category-strength {
  &:before {
    content: $icon-category-strength; 
  }
}
.icon-category-mindbody {
  &:before {
    content: $icon-category-mindbody; 
  }
}
.icon-category-zumba {
  &:before {
    content: $icon-category-zumba; 
  }
}
.icon-category-dance {
  &:before {
    content: $icon-category-dance; 
  }
}
.icon-category-crunch {
  &:before {
    content: $icon-category-crunch; 
  }
}
.icon-best-instructors {
  &:before {
    content: $icon-best-instructors;     
    color: #ff2e36;
  }
}
.icon-personal-programs {
  &:before {
    content: $icon-personal-programs;     
    color: #ff2e36;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-filter-selected {
  .path1 {
    &:before {
      content: $icon-filter-selected1;
      color: rgb(0, 0, 0);
    }
  }

  .path2 {
    &:before {
      content: $icon-filter-selected2;
      margin-left: -1em;
      color: rgb(253, 46, 53);
    }
  }

  .path3 {
    &:before {
      content: $icon-filter-selected3;
      margin-left: -1.1em;
      color: rgb(224, 224, 224);
    }
  }
}
.icon-search:before {
  content: $icon-search;
  color: #fff;
}
